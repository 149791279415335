import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Storage } from '@capacitor/storage';
const TOKEN_KEY = 'my-token';

import { map, tap, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, from } from 'rxjs';

import { getAuth, GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup, User, UserCredential } from 'firebase/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  token = '';

  auth = getAuth();

  user: UserCredential;

  constructor(private http: HttpClient) {
    this.loadToken();
  }

  async loadToken() {
    const token = await Storage.get({ key: TOKEN_KEY });
    if (token && token.value) {
      console.log('set token: ', token.value);
      this.token = token.value;
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
  }

  async login(email: string, password: string): Promise<any> {

    signInWithEmailAndPassword(this.auth, email, password)
      .then(async val => {
        this.user = val;
        this.isAuthenticated.next(true);
        return from(Storage.set({key: TOKEN_KEY, value: await this.user.user.getIdToken()}));

      })
      .catch(val => {
        console.log(val.message);
        this.isAuthenticated.next(false);
      });

  }

  logout(): Promise<void> {
    this.isAuthenticated.next(false);
    return Storage.remove({key: TOKEN_KEY});
  }
}
